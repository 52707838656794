export function optimizeContentTags(node: any, path: string) {
  if (!Array.isArray(node)) {
    return node
  }
  const [tag, props, children] = node
  if (tag === 'a') {
    // fix anchor links
    const href = props.href?.split('#')[0]
    if (path.includes(href)) {
      props.href = `${path}#${props.href?.split('#')[1]}`
    }

    // add target="_blank"
    if (props.target !== '_blank') {
      props.target = '_blank'
    }
  }
  else if (tag === 'img' && props.src.startsWith('./')) {
    props!.src = props!.src.slice(1)
  }
  // walk children if they're an array
  if (Array.isArray(children)) {
    node[2] = children.map(child => optimizeContentTags(child, path))
  }
  return node
}

interface ValidationResult {
  type: 'image' | 'link'
  path: string
  source: string
}

interface NodeProps {
  src?: string
  href?: string
  link?: string
  [key: string]: any
}

type Node = [string, NodeProps, Node[]?]

async function validateURL(url: string): Promise<{ ok: boolean, status?: number, statusText?: string }> {
  try {
    const response = await fetch(url)
    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
    }
  }
  catch {
    return { ok: false }
  }
}

export async function validateLinksAndImages(node: Node | string, path: string): Promise<ValidationResult[]> {
  if (typeof node === 'string' || !Array.isArray(node)) {
    return []
  }

  const results: ValidationResult[] = []
  const [type, props, children = []] = node

  // Validate images
  if (type === 'img' && props.src?.startsWith('http')) {
    const validation = await validateURL(props.src)
    if (!validation.ok) {
      results.push({
        type: 'image',
        path,
        source: props.src,
      })
    }
  }

  // Validate links
  if (type === 'a' || type === 'resource') {
    const link = props.href || props.link
    if (link?.startsWith('http')) {
      const validation = await validateURL(link)
      if (!validation.ok) {
        results.push({
          type: 'link',
          path,
          source: link,
        })
      }
    }
  }

  let childResults: ValidationResult[] = []
  if (Array.isArray(children)) {
    // if children is an array of 3 items and first item is string, it's a regular node, wrap in an array
    const childrenNodes = children[0] && typeof children[0] === 'string' ? [children] : children
    // @ts-expect-error untyped
    childResults = (await Promise.all(childrenNodes.flatMap(child => validateLinksAndImages(child as any, path)))) as ValidationResult[]
  }

  return [...results, ...childResults.flat()]
}

export function extractMarkdownSection(nodes: Node[], section: string): Node[] | null {
  if (!Array.isArray(nodes)) {
    return null
  }
  let start: number | undefined
  let end: number | undefined
  let sectionTag: string | undefined
  nodes.forEach((arr, index) => {
    if (!Array.isArray(arr)) {
      return
    }
    const [tag, props] = arr
    if (props?.id === section) {
      start = index
      sectionTag = tag
    }
    else if (start && tag === sectionTag) {
      end = index
    }
  })
  return start === undefined ? null : nodes.slice(start, end)
}
