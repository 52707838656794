<script setup lang="ts">
import type { AcademyContentFile } from '#academy/utils/nav'
import type { AcademyLink } from '#academy-virtual/types'
import { extractMarkdownSection } from '../utils/content'

export interface Options {
  path: AcademyLink
  disableHeadlineLink?: boolean
}

const props = defineProps<Options>()

const el = ref<HTMLElement | null>(null)
const snippet = (await queryCollection(props.path.startsWith('/academy') ? 'pages' : 'snippets').path(props.path.split('#')[0]).first()) as AcademyContentFile
if (props.path.includes('#')) {
  const extracted = extractMarkdownSection(snippet.body.value, props.path.split('#')[1])
  if (!extracted) {
    if (import.meta.dev) {
      throw new Error(`Could not find section ${props.path.split('#')[1]} in snippet ${props.path}`)
    }
  }
  else {
    snippet.body.value = extracted
  }
}

const components = computed(() => {
  // Don't add anchor links to the headline
  if (props.disableHeadlineLink) {
    return { h1: 'h1', h2: 'h2', h3: 'h3' }
  }
  return undefined
})
</script>

<template>
  <article ref="el" data-ui="UiContent" class="nuxt-content">
    <UiLoading v-if="!snippet" type="spinner" size="md" loading />
    <ContentRenderer v-else :value="snippet">
      <MDCRenderer :value="snippet" :components="components" />
    </ContentRenderer>
  </article>
</template>
